import { render, staticRenderFns } from "./DataViewSidebarBranches.vue?vue&type=template&id=134e9f4d&scoped=true&"
import script from "./DataViewSidebarBranches.vue?vue&type=script&lang=js&"
export * from "./DataViewSidebarBranches.vue?vue&type=script&lang=js&"
import style0 from "./DataViewSidebarBranches.vue?vue&type=style&index=0&id=134e9f4d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134e9f4d",
  null
  
)

export default component.exports